import { IOrderSummaryResponse } from "src/app/app-config.interface";


const orderSummaryResponseMock: IOrderSummaryResponse = {
  //Win_D1
  pricingEngineResponse: null,
  zipCode: '', // from userdata
  taxYear: '', // from userdata
  priceGroupKey: '', // from api
  priceScheduleId: '', // from api    EXAMPLE VALUES: f358f933-dd15-4060-8681-7b3c3b4b030c // 42f34615-ba48-42ce-bf55-e1e2e48f3527
  orderSummaryInfo: {
    product: {
      device: '', // (windows)
      tax: null,
      sku: '', // from api
      description: '',
      quantity: 1,
      price: 0,
      regularSalesUnitPrice: [0],
      members: [],
    },
    extraProducts: [],

    discount: null,

    autoRenewalDiscount: {
      renewAt: '',
      tax: {
        taxType: '',
        taxableAmount: 0,
        amount: 0,
        percent: 0,
      },
      sku: 'AUTO_PAY',
      description: '',
      quantity: 1,
      price: 0,
      regularSalesUnitPrice: [0],
      members: [],
    },

    couponCodeDiscount: null,
    salesTax: 0,
    total: 0,
  },
};

export class OrderSummaryResponse {
  orderSummary: IOrderSummaryResponse;
  constructor() {
    this.orderSummary = orderSummaryResponseMock;
  }
}

// {
//   id: '',
//   pricingMethod: {
//     priceGroupKey: '63D2A479',
//     priceModel: 'DOTCOM',
//     priceYear: 2021,
//     priceSchedule: 'f358f933-dd15-4060-8681-7b3c3b4b030c',
//     priceLevel: 0,
//     staticBilling: false,
//     tierPricingControlLevel: 0,
//   },

//   lineItems: [
//     {
//       tax: {
//         taxType: 'Sales',
//         taxableAmount: 0,
//         amount: 0,
//         percent: 0,
//       },
//       sku: 'TaxPrep',
//       description: 'TaxPrep',
//       quantity: 1,
//       price: 0,
//       regularSalesUnitPrice: [0],
//       members: [
//         {
//           tax: [],
//           sku: 'FDTaxPrep',
//           description: 'FDTaxPrep',
//           quantity: 1,
//           price: 0,
//           regularSalesUnitPrice: [0],
//           members: [
//             {
//               sku: 'Win_D1',
//               description: 'Deluxe Tax Software',
//               regularSalesUnitPrice: [0],
//               price: 0,
//               quantity: 1,
//               tax: {},
//             },
//           ],
//         },
//       ],
//     },
//   ],

//   total: {
//     totals: [
//       {
//         type: 0,
//         amount: 0,
//       },
//     ],
//   },
// },

//service
