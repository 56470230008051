<div>
  <hrb-layout  class="customlayout">
    <hrb-container class="secondpart">
      <hrb-card class="payment-content">
        <hrb-card-content class="cardcontent">
          <div>
            <hrb-text class="mb-sp-32" style-type="large" class="subhead"
              >Congrats on your Auto Renewal!  </hrb-text
            >
          </div>
          <br />
          <br />
          <br />
          <br />
          <div>
            <hrb-text> We've emailed you a copy of your Auto Renewal confirmation to  {{senderEmail}}.</hrb-text>
            <hrb-text>
              We will send you an email reminder in early November 
              {{subscriptionYearPlusOne}}, at least two weeks prior to your software delivery. Then when the software becomes available for delivery, we will send you an additional email message containing your software download link.</hrb-text
            >
          </div>
          <br>
          <br>
          <br>
          <div>
            <hrb-text class="mb-sp-16" style-type="medium" 
              >Common questions.  </hrb-text
            >
            <hrb-accordion type='content'> <hrb-accordion-item id="openItem"  header-text="Can I make changes to my order?"> 
              <hrb-text>If you’re unsure if you purchased the right software version, you can <a target="_blank" rel="noopener noreferrer" href="https://www.hrblock.com/tax-software/" class="common-question-link">double-check the software versions</a> on our website. If you still need help, give us a call at <span style="color: green">1-800-HRBLOCK</span>. If you are dissatisfied with the H&R Block tax preparation software for any reason before you file your return, H&R Block will refund the full purchase price you paid H&R Block for the software, exclusive of shipping and handling charges, taxes, and less any rebate you receive on the software. <a target="_blank" rel="noopener noreferrer" href="https://www.hrblock.com/guarantees/" class="common-question-link">Visit our website</a> to learn more about our H&R Block Satisfaction Guarantee policy. </hrb-text>
             </hrb-accordion-item> <hrb-accordion-item id="openItem"  header-text="Where can I find the email with my Auto Renewal info?"> <hrb-text> Search your inbox for an email from H&R Block. If you don’t find it there, check your junk or bulk email folders. Your email system might automatically set up these folders for you, and filters can sweep emails into these folders without you knowing it. </hrb-text> 
            </hrb-accordion-item> </hrb-accordion>
           
          </div>
          <br>
          <br>
          <div>
            <hrb-text 
            >
            If you’re still having issues finding the email with the download info, call us at <span style="color: green">1-800-HRBLOCK</span>.
            <br />
            <br />
            We’re here to help. </hrb-text
            >
          </div>
        </hrb-card-content>
      </hrb-card>

      
    </hrb-container>
  </hrb-layout>
</div>
