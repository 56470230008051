<div>
  <hrb-layout layout="1col" class="customlayout">
    <hrb-container class="secondpart">
      <hrb-card class="error-content">
        <hrb-card-content class="cardcontent">
          <div>
            <hrb-text class="mb-sp-32" style-type="large" class="subhead"
              >Error</hrb-text
            >
          </div>
          <br />
          <br />
          <div class="mb-sp-32">
            <hrb-text style-type="large" style="color: green"
              >PAGE NOT AVAILABALE</hrb-text
            >
          </div>
          <br />
          <br />
          <div>
            <hrb-text>
              We're sorry, the page you're attempting to reach doesn't
              exist.Please check the URL and try again.If you continue to
              receive this error, please call H&R Block Customer Support at
              <span style="color: green">1-888-482-9288</span></hrb-text
            >
          </div>
        </hrb-card-content>
      </hrb-card>
    </hrb-container>
  </hrb-layout>
</div>
