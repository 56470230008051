<div class="body">
  <hrb-layout layout="1col" class="customlayout">
    <hrb-container>
      <hrb-card class="error-content">
        <hrb-card-content class="cardcontent">
          <div>
            <hrb-text class="mb-sp-32" style-type="large" class="subhead"
              >Desktop Only
            </hrb-text>
          </div>
          <br />
          <br />

          <div>
            <hrb-text style-type="small">
              Please Activate your software on your Windows or Mac device.
            </hrb-text>
          </div>
        </hrb-card-content>
      </hrb-card>
    </hrb-container>
  </hrb-layout>
</div>
