import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends ErrorHandler {

  constructor(private loggingservice:LoggingService) {
    super();
   }
   handleError(error: Error) {
    this.loggingservice.logException(error); // Manually log exception
}
}
