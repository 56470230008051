import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BdsNgModule } from '@bds/bds-ng';
import { defineCustomElements } from '@bds/bds-core/loader';
import { FormsModule } from '@angular/forms';
import { AppConfig } from './app.config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PurchaseModule } from './purchase/purchase.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpRequestInterceptor } from './HttpRequestInterceptor';
import { DeviceCheckComponent } from './device-check/device-check.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentFallbackComponent } from './payment-fallback/payment-fallback.component';
import { ErrorHandlingService } from './shared/services/error-handling.service';

export function initializeApp(appConfig: AppConfig) {
  return () =>
    new Promise<void>(async (resolve, reject) => {
      await appConfig.loadConfig();
      resolve();
    });
}
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, DeviceCheckComponent, SubscriptionComponent, PaymentFallbackComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BdsNgModule,
    HttpClientModule,
    PurchaseModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlingService }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    defineCustomElements(window);
  }
}
