<nav class="navbar">
  <img src="assets/icons/HRBLogo.svg" alt="Company Logo" />
  <div style="padding-left: 30px !important">
    <hrb-text style-type="medium" style="color: black"
      >Auto Renewal Payment</hrb-text
    >
  </div>
</nav>
<div
  style="background-color: #f1f5f7; overflow: hidden"
  *ngIf="(purchaseService.scriptLoaded | async) && pageLoaded && !spinner"
>
  <hrb-layout layout="3to2" style="margin: 86px">
    <!-- <hrb-card> -->
    <!-- <hrb-card-content
        style="
          align-items: center;
          flex-direction: column;
          display: flex;
        "
      >
      </hrb-card-content> -->
    <orp-micro-billing-and-payment
      #billingAndPayment
      *ngIf="initalizeBillingAndPayment.start"
      [service]="guuid"
      [base_url]="base_url"
      [otherAppBillingAndPayment]="otherAppBillingAndPayment"
      [orderSummaryResponse]="orderSummaryResponse"
      [terms]="showAutoRenewalTerms"
      [sendreceiptemail]="emailRequired"
      [useNuData]="useNuData"
      [isnoncartflow]="isNonCartFlow"
      [subscriptionCreation]="subscriptionCreation"
    ></orp-micro-billing-and-payment>
    <!-- </hrb-card> -->
    <hrb-card
      *ngIf="!initalizeBillingAndPayment.error && pageLoaded && !spinner"
      id="innerpage"
      style="margin-bottom: 289px; position: sticky"
      theme="white"
    >
      <hrb-card-content
        style="align-items: center; flex-direction: column; display: flex"
      >
        <div style="padding-left: 30px !important" class="mb-sp-32">
          <hrb-text style-type="little" class="orderSummaryTitle"
            >Order Summary</hrb-text
          >
        </div>
        <img
          style="width: 340px; height: 340px; margin-left: 44px"
          [src]="bookImg"
        />
        <div class="mb-sp-32 details">
          <div class="row-item">
            <hrb-text style-type="little"> Price Today </hrb-text>
            <hrb-text style-type="little"> $0* </hrb-text>
          </div>
          <div class="row-item">
            <hrb-text style-type="little">
              Charge in Nov {{ currentYearPlusOne }}:
            </hrb-text>
            <hrb-text style-type="little">
              ${{ basePrice | number: "1.2" }}*
            </hrb-text>
          </div>
        </div>
        <hrb-text style-type="little">
          *You will be charged nothing today. Charge plus applicable tax will
          occur upon delivery of product
        </hrb-text>
      </hrb-card-content>
    </hrb-card>
  </hrb-layout>
</div>

<div *ngIf="(!(purchaseService.scriptLoaded | async) || initalizeBillingAndPayment.error || !pageLoaded) && !spinner" class="error-root">
  <hrb-layout style="margin: 86px; width: 100%">
    <hrb-card>
      <hrb-card-content class="card-content">
        <div class="close-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-exclamation-octagon-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
        </div>
        <hrb-text style-type="little" class="footer-text">
          :( Application Error
        </hrb-text>
        <hrb-text style-type="little" class="footer-text">
          Failed to Load Resources ,{{promoCodeError}} 
        </hrb-text>
      </hrb-card-content>
    </hrb-card>
  </hrb-layout>
</div>

<div *ngIf="spinner">
  <div class="spinner-wrapper">
      <p-progressSpinner
          [style]="{ width: '30px', height: '30px' }"
          strokeWidth="3"
      ></p-progressSpinner
      >Please Wait Loading..
  </div>
  <div class="backdrop"></div>
</div>

<!--
#billingAndPayment
        [orderSummaryResponse]="orderSummaryResponse" -->
