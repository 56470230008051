import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Location } from '@angular/common'
import { AppConfig } from './app.config';

@Injectable({
    providedIn: 'root'
})
export class DirectAccessGaurd implements CanActivate {

    constructor(private router: Router,private location :Location) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

       
        if(this.location.isCurrentPathEqualTo('/subscription')||
        this.location.isCurrentPathEqualTo('/payment-fallback')) {
            this.router.navigate([''])
            return false;
        }
        if(this.location.isCurrentPathEqualTo('/autorenewal')){
            window.location.href = location.origin.includes('localhost') ? 'https://orp-external-proxy-qa.hrblock.com/shoppingcart/autorenewal' :`${AppConfig.config.hrbBlockSystemUrltermsandconditions}shoppingcart/autorenewal`
            
            return false;
           
        }
       
        return true;
    }
}