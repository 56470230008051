<div *ngIf="!isDownloading" style="background-color: #f1f5f7; overflow: hidden">
  <div>
    <hrb-layout layout="2to3">
      <hrb-card theme="light-grey-1">
        <hrb-card-content>
          <img
            style="width: 340px; height: 340px; margin-left: 44px"
            [src]="bookImg"
          />
          <hrb-text></hrb-text>
        </hrb-card-content>
      </hrb-card>
      <hrb-card
        *ngIf="!isNextPage"
        style="margin-right: 102px !important"
        theme="light-grey-1"
      >
        <hrb-layout layout="3row">
          <hrb-card theme="white" class="mb-sp-32">
            <hrb-card-content class="hrb-card-content-ovveride">
              <app-activation-code
                class="box-shadow"
                #activationcode
                [forms]="purchaseForm"
              ></app-activation-code>
            </hrb-card-content>
          </hrb-card>
          <hrb-card theme="white" class="mb-sp-32">
            <hrb-card-content
              class="hrb-card-content-ovveride"
              *ngIf="purchaseForm.controls.registration"
            >
              <app-details
                class="box-shadow"
                #detailComponent
                [forms]="purchaseForm.controls.registration"
              ></app-details>
            </hrb-card-content>
          </hrb-card>
          <hrb-card theme="white" class="mb-sp-32">
            <hrb-card-content>
              <app-platform #platform class="box-shadow"></app-platform>
            </hrb-card-content>
          </hrb-card>
        </hrb-layout>
      </hrb-card>
      <hrb-card
        *ngIf="isNextPage"
        style="margin-right: 102px !important"
        id="innerpage"
        theme="white"
      >
        <hrb-card-content
          style="
            align-items: center;
            flex-direction: column;
            display: flex;
            background-color: white;
          "
        >
          <div style="padding-left: 30px !important" class="mb-sp-32">
            <hrb-text style-type="large" style="color: green"
              >Your Software is Ready.</hrb-text
            >
          </div>
          <div style="padding-left: 22px !important" class="mb-sp-32">
            <hrb-text style-type="little">{{ postDownloadMsg }}</hrb-text>
          </div>
          <hrb-button
            style="padding-left: 30px !important"
            (click)="downloadFile()"
            size="medium"
            id="dwnloadButton"
            >Download Software
          </hrb-button>
          <br />
          <hrb-button
            *ngIf="isGoBack"
            theme="secondary"
            (click)="goBack()"
            size="small"
            >Go Back</hrb-button
          >
          <div
            *ngIf="showLoader"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <hrb-loader color="primary-black"></hrb-loader>
            <div *ngIf="percentDone">{{ percentDone }} % downloaded</div>
          </div>
        </hrb-card-content>
      </hrb-card>
    </hrb-layout>
    <div
      *ngIf="isNextPage"
      style="margin-right: 69px !important"
      theme="light-grey-1"
    >
      <hrb-layout layout="2to3">
        <hrb-card theme="light-grey-1">
          <hrb-card-content>
            <hrb-text></hrb-text>
          </hrb-card-content>
        </hrb-card>
        <hrb-card theme="white">
          <hrb-layout layout="2row">
            <!-- <hrb-card theme="ui-green">
                <hrb-card-content
                  style="display: flex; justify-content: center; color: white"
                >
                  <hrb-text style-type="medium">After Download</hrb-text>
                </hrb-card-content>
              </hrb-card> -->
            <hrb-card>
              <hrb-card-content class="hrb-card-content-ovveride">
                <div class="mb-sp-32">
                  <hrb-text style-type="little" style="color: green"
                    >Please follow these steps after your download is
                    complete.</hrb-text
                  >
                </div>
              </hrb-card-content>
            </hrb-card>

            <hrb-card>
              <hrb-card-content class="hrb-card-content-ovveride">
                <hrb-layout layout="3col">
                  <hrb-card theme="ui-green">
                    <hrb-card-content style="color: white">
                      <hrb-text class="mb-sp-8">STEP 1</hrb-text>
                      <div class="mb-sp-32">
                        <hrb-text style-type="large">Open</hrb-text>
                      </div>
                      <div class="mb-sp-32">
                        <hrb-text style="font-weight: 400" style-type="little">
                          <span> {{ step1Message }}</span>
                        </hrb-text>
                      </div>
                    </hrb-card-content>
                  </hrb-card>
                  <hrb-card theme="ui-green">
                    <hrb-card-content style="color: white">
                      <hrb-text class="mb-sp-8">STEP 2</hrb-text>
                      <div class="mb-sp-32">
                        <hrb-text style-type="large">Allow</hrb-text>
                      </div>
                      <div class="mb-sp-32">
                        <hrb-text style="font-weight: 400" style-type="little">
                          {{ step2Message }}</hrb-text
                        >
                      </div>
                    </hrb-card-content>
                  </hrb-card>
                  <hrb-card theme="ui-green">
                    <hrb-card-content style="color: white">
                      <hrb-text class="mb-sp-8">STEP 3</hrb-text>
                      <div class="mb-sp-32">
                        <hrb-text style-type="large">Launch</hrb-text>
                      </div>
                      <div class="mb-sp-32">
                        <hrb-text style="font-weight: 400" style-type="little">
                          {{ step3Message }}</hrb-text
                        >
                      </div>
                    </hrb-card-content>
                  </hrb-card>
                </hrb-layout>
              </hrb-card-content>
            </hrb-card>
            <hrb-card>
              <hrb-card-content class="hrb-card-content-ovveride">
                <div class="mb-sp-32">
                  <hrb-text style-type="little" style="color: black"
                    >If you would like to back up your software to use whenever
                    you need it, save the downloaded file to a personal storage
                    device (e.g., USB drive, external hard drive, cloud account,
                    etc.)</hrb-text
                  >
                </div>
              </hrb-card-content>
            </hrb-card>
          </hrb-layout>
        </hrb-card>
      </hrb-layout>
    </div>
  </div>

  <div
    *ngIf="!isNextPage"
    style="
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      margin-right: 3%;
      padding: 5px;
      align-items: baseline;
    "
  >
    <!-- <hrv-label
      style="color: red"
      *ngIf="
        !purchase.activationCode ||
        !purchase.registration.firstName ||
        !purchase.registration.lastName ||
        !purchase.registration.address ||
        !purchase.registration.zip ||
        !purchase.registration.state ||
        !purchase.registration.email ||
        !purchase.registration.city
      "
      >Please fill all the required details</hrv-label
    >&nbsp;-->
    <div title="Please Fill all the required fields" class="visible">
      <hrb-button (click)="nextPage()" size="medium" [disabled]="showLoader"
        >Next</hrb-button
      >
    </div>
  </div>
</div>
<div *ngIf="showLoader">
  <div
    style="
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: gray;
      opacity: 0.2;
      z-index: 10000;
    "
  ></div>
</div>

<div *ngIf="recaptchaload">
  <recaptcha [hide]="false" [sitekey]="siteKey"> </recaptcha>
</div>

<hrb-modal
  id="errorModal"
  size="small"
  modal-title="An Error has occurred."
  variant="header-small-title"
>
  <hrb-text style="color: red">{{ postDownloadMsg }}</hrb-text>
</hrb-modal>

<!-- <hrb-modal
  id="sinUpModal"
  size="small"
  modal-title="Skip the line next year."
  variant="header-small-title"
>
  <hrb-text style="color: green"
    >You can sign up for subscription service and have the software delivered
    directly to your e-mail inbox next year.</hrb-text
  >
  <br /><br />&nbsp;
  <hrb-button (click)="goToLink()" size="medium">SignUp</hrb-button>
</hrb-modal> -->

<hrb-modal
  id="paymentModal"
  size="medium"
  modal-title="Skip the line next year."
  variant="header-small-title"
>
  <div class="top-container payment-modal">
    <div class="label-container">
      <img
        alt="Auto Renewal Logo"
        class="auto_logo"
        src="assets/icons/auto-renewalLogo.svg"
      />
      <hrb-text style-type="large">Auto Renewal</hrb-text>
      <!-- <div class="tag" data-automation-label="Auto-Renewal-Tag">
        <hrb-text style-type="little">New!</hrb-text>
      </div> -->
    </div>

    <div class="content content-heading">
      <br />
      <hrb-text style-type="little"
        >Save time by opting in to the automatic renewal of your software
        today.</hrb-text
      >
    </div>
    <div class="content content-body">
      <div class="description-container">
        <div class="autorenewal-details">
          <div class="autorenewal-benefit-details">
            <br />
            <hrb-text
              style-type="small"
              data-automation-label="Auto-Renewal-Benefits"
            >
              Benefits:
            </hrb-text>
            <ul id="benefits-list">
              <li data-automation-label="Auto-Renewal-Benefits-0">
                <hrb-text style-type="little">
                  Lock in today's price for next year's software.
                </hrb-text>
              </li>
              <li data-automation-label="Auto-Renewal-Benefits-1">
                <hrb-text style-type="little">
                  Convenient software downloads every year.
                </hrb-text>
              </li>
            </ul>
          </div>
          <div class="autorenewal-term-details">
            <div
              class="autorenewal-term-description"
              data-automation-label="Auto-Renewal-Terms"
            >
              <hrb-text style-type="small"> Heres how it works: </hrb-text>
            </div>
            <ul id="terms-list">
              <!---->
              <li data-automation-label="Auto-Renewal-Terms-0">
                <hrb-text style-type="little">
                  No commitments — you can cancel anytime.
                </hrb-text>
              </li>
              <li data-automation-label="Auto-Renewal-Terms-1">
                <hrb-text style-type="little">
                  We'll email you before we charge your card, and give you the
                  choice to opt out.
                </hrb-text>
              </li>
              <li data-automation-label="Auto-Renewal-Terms-2">
                <hrb-text style-type="little">
                  We'll charge your card in November of {{subscriptionYearPlusOne}} and email you the
                  download link.
                </hrb-text>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="content see-more-less"
      data-automation-action="Auto-Renewal-SeeLessOrMore"
      tabindex="0"
    >
      <hrb-text style-type="body-copy"
        ><a href="javascript:void(0)" #seeText (click)="toggleSeeMore(seeText)"
          >See more</a
        >
      </hrb-text>
    </div> -->
  </div>

  <br /><br />&nbsp;
  <hrb-button (click)="goToPaymentsPage()" size="medium">Sign Up</hrb-button>
  <hrb-modal
    id="sinUpModal2"
    size="medium"
    variant="header-small-title"
    modal-title=""
  >
    <div style="margin-top: -37px; margin-bottom: 37px">
      <img src="../../../assets/icons/HRBLogo.svg" height="45px" alt="logo" />
    </div>
    <hrb-text style="color: black; font-weight: bold"
      >Download Limit Reached</hrb-text
    >
    <br /><br />
    <hrb-text style="color: green"
      >You have reached the maximum number of
      <strong>6</strong> downloads</hrb-text
    >
    <br /><br />
    <hrb-text style="color: green"
      >If you believe this is an error, please give us a call at 1-800-HRBLOCK
      (1-800-472-5625).</hrb-text
    >
  </hrb-modal>
</hrb-modal>
