import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderSummaryResponse } from '../app-config.interface';
import { AppConfig } from '../app.config';
import { OrderSummaryResponse } from '../purchase/models/subscription.model';
import { PurchaseService } from '../purchase/services/purchase.service';
import { LoggingService } from '../shared/services/logging.service';
declare var window: any;
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  public bookImg: string;
  //currentYear = AppConfig.config.year;
  currentYear = `${AppConfig.config.year}`;
  currentYearNumber = Number(this.currentYear);
  currentYearPlusOne = (this.currentYearNumber + 1).toString();
  scriptLoaded = false;
  guuid = '';
  base_url = location.origin.includes('localhost') ? "https://activate-qa.hrblock.com/orp/": AppConfig.config.hrbBlockSystemUrl;
  baseUrl = 'https://api-nd.hrblock.com/2.2/w/';
  ndClientId = 'w-480703';
  otherAppBillingAndPayment = true;
  subscriptionCreation =true;
  emailRequired =true
  useNuData =false;
  isNonCartFlow = false;
  initalizeBillingAndPayment = { start: false, error: false };
  showAutoRenewalTerms = 'showAutoRenewalTerms';
  billingYear: string;
  billingEmail: string;
  orderSummaryResponse: IOrderSummaryResponse;
  customerPrice: number;
  basePrice: number;
  promo: string;
  osType: string;
  pageLoaded:boolean =false;
  spinner :boolean =true;
  promoCodeError :string ="";
  @ViewChild('billingAndPayment') billingAndPayment;

 

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    public purchaseService: PurchaseService,
    private navigateRoute: Router,
    private loggingService: LoggingService
  ) {
    this.guuid = this.purchaseService.getServiceId();
    this.loggingService.logPageView('SubscriptionComponent', '/subscription');
  }

  ngAfterViewInit() {
    window.addEventListener('continueToOrderConfirmation', (event) => {
      console.log('event fire', event);
      this.navigateRoute.navigate(['payment-fallback'], {
        state: { year: Number(this.billingYear), email: this.billingEmail },
      });
      localStorage.getItem("OS")
    });
    window.addEventListener('setBillingInformation', (event) => {
      console.log('event fire', event);
      this.billingEmail = event.detail.email;
    });
  }

  ngOnInit(): void {
    this.pageLoaded =false;
     this.spinner =true;
     this.promoCodeError =""
     this.orderSummaryResponse = new OrderSummaryResponse().orderSummary;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.promo = params['promoCode'];
      this.osType = localStorage.getItem("OS")
      this.getOS();
      this.getSku().then((skuResponse: any) => {
        this.checkAutorenewalStatus(skuResponse).then((response: any) => {
          this.getPrice(response).then((priceResponse: any) => {
            if (priceResponse) {
              this.pageLoaded = true;
              this.nuDataInit();
              

              this.billingYear = AppConfig.config.year
              // setting billing data from queryparam
              this.orderSummaryResponse.priceGroupKey = response['priceGroupKey'];
              this.orderSummaryResponse.orderSummaryInfo.product.device =
                response['device'];
              this.orderSummaryResponse.orderSummaryInfo.product.sku = response['sku'];
              this.orderSummaryResponse.taxYear = response['taxYear'];
              this.orderSummaryResponse.zipCode = params['zipCode'];
                    this.orderSummaryResponse.orderSummaryInfo.product.description = response['description'];


              let aliasFirstChar = this.promo[0];
              let aliasFirstCharUpper = aliasFirstChar.toLocaleUpperCase();
              let aliasLastTwoChars = this.promo.substring(1); // Get the last two characters
              if (aliasLastTwoChars) {
                this.bookImg = 'assets/icons/';
                switch (aliasLastTwoChars.toLocaleUpperCase()) {
                  // basic

                  case 'BF':
                    if (aliasFirstCharUpper != 'C') {
                      this.promoCodeError = "Please enter valid promoCode"
                      this.spinner = false;
                      this.initalizeBillingAndPayment.error = true;

                      break;
                    }

                  case 'RC':
                  case 'WC':
                  case 'CC':
             
                  case 'RC':
                  case 'WC':
                  case 'CC':
                  
               
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B1}`;
                    break
                    case 'BC':
                  
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_B1_blank_rgb}`;
                    break;
                  // delux
                  case 'DF':
                    if (aliasFirstCharUpper != 'C') {
                      this.promoCodeError = "Please enter valid promoCode"
                      this.spinner = false;
                      this.initalizeBillingAndPayment.error = true;

                      break;
                    }


                  case 'RD':
                  case 'CD':
                  case  'MD':
                

          

             
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
                    break;
                    //best buy
                  case 'BD':
                 
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_D1_blank_rgb}`;
                    break;
                  case 'HJ':
                   case 'MJ':
               
                    
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;

                      break;
                  // delux + state
              
                
             
                  case 'DA':
                    if (aliasFirstCharUpper != 'C') {
                      this.promoCodeError = "Please enter valid promoCode"
                      this.spinner = false;
                      this.initalizeBillingAndPayment.error = true;

                      break;
                    }
                  case 'RJ':
                  case 'NJ':
                  case 'CJ':
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
                    break;
                 //Best Buy
                 case 'BJ':
                 
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_D2_blank_rgb}`;
                     break;
                  // premium
                 
               
                  
                  case 'PA':
                    if (aliasFirstCharUpper != 'C') {
                      this.promoCodeError = "Please enter valid promoCode"
                      this.spinner = false;
                      this.initalizeBillingAndPayment.error = true;

                       break;
                    }

                  case 'RM':
                  case 'WM':
                  case 'CM':
                  
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_P2}`;
                    break;

                //Best Buy
                  case 'DBM':
                
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_P2_blank_rgb}`;
                   break;
                  // business
                 
           
               
                  case 'PH':
                    if (aliasFirstCharUpper != 'C') {
                      this.promoCodeError = "Please enter valid promoCode"
                      this.spinner = false;
                      this.initalizeBillingAndPayment.error = true;

                      break;
                    }

                  case 'RH':
                  case 'WH':
                  case 'CH':

             
                   //Best Buy
                  case 'BH':
                 
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WebTile_Generic_H2_blank_rgb}`;
                   break;

                  // Walmart
                  case 'WD':
             
                   // 'walmart_D1.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;
                    break;
              
                  case 'WJ':
                  //'walmart_D2.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;
                    break;

                  // Amzon
                  case 'ZC':
              
                     //'Amazon_B1.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B1}`;

                    break;
                  case 'ZD':
               
                    //'Amazon_D1.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D1}`;

                    break;
              
                  case 'ZJ':
                    // 'Amazon_D2.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_D2}`;

                    break;
                  case 'ZM':
               
                    //'Amazon_P1.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_P2}`;

                    break;

                
                  case 'ZH':
                     //'Amazon_P1_B1.png';
                    this.bookImg += `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].Win_B2}`;

                    break;

                  default:
                    break;
                }
              }
            }
            this.spinner =false;
          });
        })
      });
    });


  }

  private getOS() {
    if (this.osType === null || this.osType === undefined || !this.osType) {
      if (navigator.platform === 'Mac68K' ||
        navigator.platform === 'MacPPC' ||
        navigator.platform === 'MacIntel') {
        this.osType = 'M';
      } else {
        this.osType = 'W';
      }
    }
  }

  //NUData initial state
  nuDataInit() {
    let base_Url = 'https://api-nd.hrblock.com/2.2/w/';
    let nd_ClientId = 'w-480703';
    var sessionId = String(this.guuid);
    var ndClientId = nd_ClientId;
    var baseUrl = base_Url + nd_ClientId + '/sync/js/';
    console.log('baseUrl', baseUrl);
    console.log('ndclient', ndClientId);

    (function (w, d, s, u, q, js, fjs, nds) {
      nds = w.ndsapi || (w.ndsapi = {});
      nds.config = {
        q: [],
        ready: function (cb) {
          this.q.push(cb);
        },
      };
      js = d.createElement(s);
      fjs = d.getElementsByTagName(s)[0];
      js.src = u;
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = function () {
        nds.load(u);
      };
    })(window, document, 'script', baseUrl);

    var nds = window.ndsapi;

    console.log('Service Id ', sessionId);
    console.log('nuDataInit', nds);
    nds.config.ready(function () {
      nds.setSessionId(sessionId);
      nds.setPlacement('Purchase');
      nds.setPlacementPage('1');
    });

    console.log('Nudata Sessionid', nds.config);
  }

  checkAutorenewalStatus(skuResponse) {
    return new Promise((resolve, reject) => {
      this.purchaseService.checkForAutorenewal().subscribe(
        (data) => {
          if (skuResponse === undefined) {
            skuResponse = { sku: "" }
          }
          resolve({
            autoRenewal: true,
            priceGroupKey: data.priceGroupKey,
            sku: skuResponse.sku,
            device:
              `(${this.osType === 'M' ? 'Mac' : 'Windows'})` || '',
            taxYear: data.taxYear,
          });
          this.initalizeBillingAndPayment.start = true;
        },
        (error) => {
          reject(error);
          this.spinner =false;
          this.initalizeBillingAndPayment.error = true;
           this.loggingService.logException(error);
        }
      );
    });
  }

  getSku() {
    return new Promise((resolve, reject) => {
      this.purchaseService.getSku(this.promo.toUpperCase(), this.osType)
        .subscribe((sku) => {
          if(sku){
          resolve({
            sku: sku
          });
          
          this.initalizeBillingAndPayment.start = true;
        }
        else{
          this.promoCodeError ="Please enter valid promoCode"
          this.spinner =false;
          this.initalizeBillingAndPayment.error = true;
        }
        },
          (error) => {
            reject(error);
            this.spinner =false;
            this.initalizeBillingAndPayment.error = true;
             this.loggingService.logException(error);
          }
        )
    })
  }

  getPrice(response) {
    return new Promise((resolve, reject) => {
      this.purchaseService
        .getAutoRenewalPrices(response.priceGroupKey)
        .subscribe((data) => {
          if (data) {
             this.orderSummaryResponse.priceScheduleId = data.priceScheduleID;
            const price = data.prices.find(
              (e) =>
                e.sku === response.sku
            );
            this.customerPrice = parseFloat(price.priceGroupPrice);
            this.basePrice = parseFloat(price.basePrice);
            this.initalizeBillingAndPayment.start = true;
            resolve(true)
          }

        },
          error => {
            reject(error);
            this.spinner =false;
            console.log("Error retrieving price details", error)
            this.initalizeBillingAndPayment.error = true;
          }
        );
    })
  }
}
