<hrb-card>
  <hrb-card-content>
    <div class="mb-sp-32">
      <span class="numberWrapper">3</span>&nbsp;
      <hrb-text style-type="small">Select your platform.</hrb-text>
    </div>
    <div class="mb-sp-16">
      <hrb-text style-type="little"
        >What kind of computer are you installing this software on?</hrb-text
      >
    </div>
    <hrb-radio
      name="transportation"
      value="W"
      (ngModelChange)="changeInPlat()"
      [(ngModel)]="platform.os"
      theme="light-grey-3"
    >
      Windows
    </hrb-radio>    
    <hrb-radio *ngIf="!isMacDisabled"      
      name="transportation"
      value="M"
      (ngModelChange)="changeInPlat()"
      [(ngModel)]="platform.os"
      theme="light-grey-3"
    >
      macOS
    </hrb-radio>
  </hrb-card-content>
</hrb-card>
