import { Component, OnInit } from '@angular/core';
import { LoggingService } from '../shared/services/logging.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private loggingService: LoggingService) {
   
  }

  ngOnInit(): void {
    this.loggingService.logPageView('PageNotFoundComponent','');
  }
}
