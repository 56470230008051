import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../shared/services/logging.service';
import { AppConfig } from '../app.config';


@Component({
  selector: 'app-payment-fallback',
  templateUrl: './payment-fallback.component.html',
  styleUrls: ['./payment-fallback.component.scss'],
})
export class PaymentFallbackComponent implements OnInit {
  questions1:string = 'If you’re unsure if you purchased the right software version, you can <a target="_blank" rel="noopener noreferrer" href="https://www.hrblock.com/tax-software/" class="common-question-link">double-check the software versions</a> on our website. If you still need help, give us a call at 1-800-HRBLOCK. If you are dissatisfied with the H&R Block tax preparation software for any reason before you file your return, H&R Block will refund the full purchase price you paid H&R Block for the software, exclusive of shipping and handling charges, taxes, and less any rebate you receive on the software. <a target="_blank" rel="noopener noreferrer" href="https://www.hrblock.com/guarantees/" class="common-question-link">Visit our website</a> to learn more about our H&R Block Satisfaction Guarantee policy.'
  subscriptionYear: number;
  subscriptionYearPlusOne: number;
  senderEmail: string;
  constructor(private router: Router,private loggingService: LoggingService) {
    this.subscriptionYear = Number(`${AppConfig.config.year}`);
    this.subscriptionYearPlusOne = (this.subscriptionYear + 1);

    this.subscriptionYearPlusOne = this.subscriptionYear + 1;
    this.senderEmail =this.router.getCurrentNavigation().extras.state?.email;

    this.loggingService.logPageView('PaymentFallbackComponent','/payment-fallback');
  }
  ngOnInit(): void {}
}
