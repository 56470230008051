import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PurchaseComponent } from './purchase/purchase/purchase.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DeviceCheckComponent } from './device-check/device-check.component';
import { PaymentFallbackComponent } from './payment-fallback/payment-fallback.component';
import { DirectAccessGaurd } from './DirectAccessGaurd';

const routes: Routes = [
  /* first Method - Passing Code as path

{ path: '', component: PageNotFoundComponent },
{
  path: 'XYZ',
  component: PurchaseComponent
},*/

  /*Second Methd- Passing code as param- start */
  { path: '', component: PageNotFoundComponent,canActivate:[DirectAccessGaurd]   },
  { path: 'subscription', component: SubscriptionComponent,canActivate:[DirectAccessGaurd] },
  { path: 'noAccess', component: DeviceCheckComponent,canActivate:[DirectAccessGaurd]  },
  { path: 'payment-fallback', component: PaymentFallbackComponent,canActivate:[DirectAccessGaurd]},
  {
    path: ':id',
    component: PurchaseComponent,canActivate:[DirectAccessGaurd] 
  },
  // { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
  /* second methd end */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
