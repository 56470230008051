export const ALLSTATES = [
  ,
  /*'FD'  RTN_FEDERAL                0 */ 'AL' /* RTN_STATE_ALABAMA          1 */,
  'AK' /* RTN_STATE_ALASKA           2 */,
  'AZ' /* RTN_STATE_ARIZONA          3 */,
  'AR' /* RTN_STATE_ARKANSAS         4 */,
  'CA' /* RTN_STATE_CALIFORNIA       5 */,
  'CO' /* RTN_STATE_COLORADO         6 */,
  'CT' /* RTN_STATE_CONNECTICUT      7 */,
  'DE' /* RTN_STATE_DELAWARE         8 */,
  'DC' /* RTN_STATE_DC               9 */,
  'FL' /* RTN_STATE_FLORIDA         10 */,
  'GA' /* RTN_STATE_GEORGIA         11 */,
  'HI' /* RTN_STATE_HAWAII          13 */,
  'ID' /* RTN_STATE_IDAHO           14 */,
  'IL' /* RTN_STATE_ILLINOIS        15 */,
  'IN' /* RTN_STATE_INDIANA         16 */,
  'IA' /* RTN_STATE_IOWA            17 */,
  'KS' /* RTN_STATE_KANSAS          18 */,
  'KY' /* RTN_STATE_KENTUCKY        19 */,
  'LA' /* RTN_STATE_LOUISIANA       20 */,
  'ME' /* RTN_STATE_MAINE           21 */,
  'MD' /* RTN_STATE_MARYLAND        22 */,
  'MA' /* RTN_STATE_MASSACHUSETTS   23 */,
  'MI' /* RTN_STATE_MICHIGAN        24 */,
  'MN' /* RTN_STATE_MINNESOTA       25 */,
  'MS' /* RTN_STATE_MISSISSIPPI     26 */,
  'MO' /* RTN_STATE_MISSOURI        27 */,
  'MT' /* RTN_STATE_MONTANA         28 */,
  'NE' /* RTN_STATE_NEBRASKA        29 */,
  'NV' /* RTN_STATE_NEVADA          30 */,
  'NH' /* RTN_STATE_NEW_HAMPSHIRE   31 */,
  'NJ' /* RTN_STATE_NEW_JERSEY      32 */,
  'NM' /* RTN_STATE_NEW_MEXICO      33 */,
  'NY' /* RTN_STATE_NEW_YORK        34 */,
  'NC' /* RTN_STATE_NORTH_CAROLINA  35 */,
  'ND' /* RTN_STATE_NORTH_DAKOTA    36 */,
  'OH' /* RTN_STATE_OHIO            37 */,
  'OK' /* RTN_STATE_OKLAHOMA        38 */,
  'OR' /* RTN_STATE_OREGON          39 */,
  'PA' /* RTN_STATE_PENNSYLVANIA    40 */,
  'PR' /* RTN_STATE_PUERTO_RICO     41 */,
  'RI' /* RTN_STATE_RHODE_ISLAND    42 */,
  'SC' /* RTN_STATE_SOUTH_CAROLINA  43 */,
  'SD' /* RTN_STATE_SOUTH_DAKOTA    44 */,
  /* 'SY',*/
  'TN' /* RTN_STATE_TENNESSEE       45 */,
  'TX' /* RTN_STATE_TEXAS           46 */,
  'UT' /* RTN_STATE_UTAH            47 */,
  'VT' /* RTN_STATE_VERMONT         48 */,
  'VA' /* RTN_STATE_VIRGINIA        49 */,
  'VI' /* RTN_STATE_VIRGIN_ISLANDS  50 */,
  'WA' /* RTN_STATE_WASHINGTON      51 */,
  'WV' /* RTN_STATE_WEST_VIRGINIA   52 */,
  'WI' /* RTN_STATE_WISCONSIN       53 */,
  'WY',
  'AA',
  'AE',
  'AP',
  'AS',
  'FM',
  'GU',
  'MH',
  'MP',
  'PW',
];

export const STATESWITHCODE = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'ARMED FORCES AMERICA', abbreviation: 'AA' },
  { name: 'ARMED FORCES', abbreviation: 'AE' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' },
];
