export class Registration {
  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.address = '';
    this.city = '';
    this.state = '';
    this.zip = '';
    this.email = '';
    this.confirmEmail = '';
    this.emailOptIn = false;
  }

  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  confirmEmail?: string;
  emailOptIn: boolean;
}
