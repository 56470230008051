import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-device-check',
  templateUrl: './device-check.component.html',
  styleUrls: ['./device-check.component.scss'],
})
export class DeviceCheckComponent implements OnInit {
  deviceInfo = null;
  isSupported = true;
  public parameterValue: any;
  constructor(
    private deviceService: DeviceDetectorService,
    private navigateRoute: Router
  ) {
    this.deviceFunction();
  }

  ngOnInit(): void {
    if (this.isSupported) {
      this.navigateRoute.navigate(['']);
    }
  }

  deviceFunction(): boolean {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    if (
      this.deviceService.isMobile() ||
      this.deviceService.isTablet() ||
      this.deviceService.os.toLowerCase() == 'chromeos'
    ) {
      this.isSupported = false;
    }
    return this.isSupported;
  }
}
