import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ENDPOINT } from 'src/environments/endpoint';
import { AppConfig } from './app.config';
import { DeviceCheckComponent } from './device-check/device-check.component';


import { PurchaseService } from './purchase/services/purchase.service';
import { LoggingService } from './shared/services/logging.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'download-box';
  titleImage = 'assets/icons/logo.png';
  resDevice = true;
  public parameterValue: any;
  userIP = ''
  constructor(
    @Inject(DOCUMENT) document,
    private deviceService: DeviceDetectorService,
    private navigateRoute: Router,
   private router: ActivatedRoute, 
   private appconfig: AppConfig,
    private purchaseService: PurchaseService,
    private httpClient: HttpClient,
  private logging:LoggingService
  ) {}
  ngOnInit(): void {

    window.scrollTo(0, 0);
    let deviceComponent = new DeviceCheckComponent(
      this.deviceService,
      this.navigateRoute
    );
    this.resDevice = deviceComponent.deviceFunction();
    if (!this.resDevice) {
      this.navigateRoute.navigate(['noAccess']);
    }
    if (this.resDevice && this.navigateRoute.url === '/noAccess') {
      this.navigateRoute.navigate(['']);
    }
    this.loadBlockDesignSystem();
    this.loadIp();  
    
  }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   let element = document.getElementById('headerbar');
  //   if (window.pageYOffset > element.offsetTop) {
  //     element.classList.add('stickyhead');
  //   } else {
  //     element.classList.remove('stickyhead');
  //   }
  // }

  loadBlockDesignSystem() {
    this.injectToIndexHead(
      `${AppConfig.config.hrbBlockSystemUrl}${ENDPOINT.BILLING_PAYMENT}`
    );
    this.injectFontFile(
      `${AppConfig.config.hrbBlockSystemUrl}${ENDPOINT.FONT}`
    );
  }

  injectToIndexHead(src: string) {
    var script = document.createElement('script');
    script.src = src;
    document.head.appendChild(script);
    script.onload = (ev: any) => {
      this.purchaseService.logScriptLoad(true);
    };
    script.onerror = (error: any) => {
      this.purchaseService.logScriptLoad(false);
    };
  }

  injectFontFile(fileUrl: string) {
    var graphik_font = new (window as any).FontFace(
      'Graphik Semibold Web',
      `url('${fileUrl}')`
    );
    graphik_font
      .load()
      .then(function (loaded_face) {
        // loaded_face holds the loaded FontFace
        (document as any).fonts.add(loaded_face);
        console.log('font loaded');
      })
      .catch(function (error) {
        // error occurred
      });
  }




  loadIp() {
    console.log("within loadip function")
    this.httpClient.get('https://jsonip.com').subscribe(
      (value:any) => {
        console.log(value);
        this.userIP = value.ip;
        console.log("the user's ip address", this.userIP)
        this.logging.logTrace("the user's ip address",{["ip"]: this.userIP})
      },
      (error) => {
        console.log(error);
      }
    );

    
  }
}
