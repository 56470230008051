import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivationCodeDetails } from '../models/activa-code.model';
import { Registration } from '../models/details.model';
import { Platform } from '../models/platform.model';

@Injectable({
  providedIn: 'root',
})
export class PurchaseDataService {
  public actCode: BehaviorSubject<ActivationCodeDetails>;
  public details: BehaviorSubject<Registration>;
  public platf: BehaviorSubject<Platform>;
  constructor() {
    this.actCode = new BehaviorSubject<ActivationCodeDetails>(null);
    this.details = new BehaviorSubject<Registration>(null);
    this.platf = new BehaviorSubject<Platform>(null);
  }

  getActCode(): Observable<ActivationCodeDetails> {
    return this.actCode;
  }

  getDetails(): Observable<Registration> {
    return this.details;
  }

  getPlatf(): Observable<Platform> {
    return this.platf;
  }
}
