import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-direction-arrow-animation',
  templateUrl: './direction-arrow-animation.component.html',
  styleUrls: ['./direction-arrow-animation.component.scss'],
})
export class DirectionArrowAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
