import { ActivationCodeDetails } from './activa-code.model';
import { Registration } from './details.model';
import { Platform } from './platform.model';
import { UntypedFormControl } from '@angular/forms';

export class Purchase {
  constructor() {
    this.registration = new Registration();
    this.activationCode = '';
    this.promoCode = '';
    this.year = '';
    this.OSType = '';
  }
  activationCode: string;
  promoCode: string;
  year: string;
  OSType: string;
  registration: Registration;
}

export const VALIDATIONMSG = {
  REQUIRED: '',
  EMAIL: 'Please enter valid Email',
};

export function ConfirmEmailValidator(confirmEmailInput: string) {
  let confirmEmailControl: UntypedFormControl;
  let emailControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!confirmEmailControl) {
      confirmEmailControl = control;
      emailControl = control.parent.get(confirmEmailInput) as UntypedFormControl;
      emailControl.valueChanges.subscribe(() => {
        confirmEmailControl.updateValueAndValidity();
      });
    }

    if (
      emailControl.value.toLocaleLowerCase() !==
      confirmEmailControl.value.toLocaleLowerCase()
    ) {
      return {
        notMatch: true,
      };
    }
    return null;
  };
}
