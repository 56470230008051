import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseComponent } from './purchase/purchase.component';
import { ActivationCodeComponent } from './activation-code/activation-code.component';
import { DetailsComponent } from './details/details.component';
import { PlatformComponent } from './platform/platform.component';
import { BdsNgModule } from '@bds/bds-ng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectionArrowAnimationComponent } from './direction-arrow-animation/direction-arrow-animation.component';
import { ReCaptchaModule } from 'angular-recaptcha3';

@NgModule({
  declarations: [
    PurchaseComponent,
    ActivationCodeComponent,
    DetailsComponent,
    PlatformComponent,
    DirectionArrowAnimationComponent,
  ],
  imports: [
    CommonModule,
    BdsNgModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    ReCaptchaModule.forRoot({ language: 'en' }),
  ],
  exports: [
    PurchaseComponent,
    ActivationCodeComponent,
    DetailsComponent,
    PlatformComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PurchaseModule {}
export interface IRecaptchaOption {
  sitekey: string;
  theme?: string;
  type?: string;
  tabindex?: number;
  badge?: string;
}
