import { Component, OnInit } from '@angular/core';
import { Platform } from '../models/platform.model';
import { PurchaseDataService } from '../services/purchase-data.service';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
})
export class PlatformComponent implements OnInit {
  public platform = new Platform();
  constructor(private purchaseDataService: PurchaseDataService) {
    this.platform = {
      os: '',
    };
  }
  // MAC 
  get isMacDisabled(): boolean {
    let url = document.location.href.split('/');
    let promoCode = url[url.length - 1];
    return promoCode.toUpperCase().endsWith('H');
    
  }

  ngOnInit(): void {
    if (this.purchaseDataService.platf.value) {
      this.platform = this.purchaseDataService.platf.value;
    }
    this.findPlatform();
    this.changeInPlat();
  }
  changeInPlat() {
    this.purchaseDataService.platf.next(this.platform);
  }
  findPlatform() {
    if (
      navigator.platform === 'Mac68K' ||
      navigator.platform === 'MacPPC' ||
      navigator.platform === 'MacIntel'
    ) {
      this.platform.os = 'M';
    } else {
      this.platform.os = 'W';
    }
  }
}
