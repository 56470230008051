import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from './app-config.class';

@Injectable()
export class AppConfig {
  static config: Config;

  constructor(private httpClient: HttpClient) {}

  loadConfig() {
    const jsonFile = this.getConfiguration();

    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .get(jsonFile)
        .toPromise()
        .then((response: Config) => {
          AppConfig.config = response as Config;
          resolve();
        })
        .catch((response: any) => {
          reject(
            `could not load file '${jsonFile}' : ${JSON.stringify(response)}`
          );
        });
    });
  }

  public getConfiguration() {
    if (location.origin.includes('localhost')) {
      return 'assets/local.config.json';
    } else if (location.origin.includes('dev')) {
      return 'assets/dev.config.json';
    } else if (location.origin.includes('qa')) {
      return 'assets/qa.config.json';
    } else {
      return 'assets/prod.config.json';
    }
  }
}
