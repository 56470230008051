import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  blob: string;
  error: boolean;
  href: unknown;
  busy: boolean;

  constructor(private http: HttpClient) {}
  downloadFile(href: string) {
    return this.http.get(href, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });
  }
}
