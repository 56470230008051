import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { Purchase } from '../models/purchase.model';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  headers: any;
  private $route = new BehaviorSubject('');
  public route = this.$route.asObservable();

  private $scriptLoaded = new Subject();
  public scriptLoaded = this.$scriptLoaded.asObservable();

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
  }

  logScriptLoad(ev) {
    this.$scriptLoaded.next(ev);
  }

  getREgistrationObject(details) {
    let route = this.$route.value.toUpperCase();
    let aliasLastTwoChars = route.substring(1); // Get the last two characters
    let aliasLastTwoCharsUpper = aliasLastTwoChars.toUpperCase();
    return aliasLastTwoCharsUpper === 'HJ' ||
      aliasLastTwoCharsUpper === 'BF' ||
      aliasLastTwoCharsUpper === 'DF' ||
      aliasLastTwoCharsUpper === 'DA' ||
      aliasLastTwoCharsUpper === 'PA' ||
      aliasLastTwoCharsUpper === 'PH' ||
      aliasLastTwoCharsUpper === 'BC' ||
      aliasLastTwoCharsUpper === 'BD' ||
      aliasLastTwoCharsUpper === 'BJ' ||
      aliasLastTwoCharsUpper === 'BM' ||
      aliasLastTwoCharsUpper === 'BH'
      ? {
          Email: details.registration.email,
          EmailOptIn: details.registration.emailOptIn,
        }
      : {
          FirstName: details.registration.firstName,
          LastName: details.registration.lastName,
          City: details.registration.city,
          State: details.registration.state,
          zip: details.registration.zip,
          Email: details.registration.email,
          EmailOptIn: details.registration.emailOptIn,
          Address: details.registration.address,
        };
  }

  downloadDetails(details: Purchase): Observable<any> {
    let dLiabRequest = {
      ActivationCode: details.activationCode,
      Alias: details.promoCode,
      Year: details.year,
      OSType: details.OSType,
      Registration: this.getREgistrationObject(details),
    };

    return this.http.post<any>(
      `${AppConfig.config.rootApi}DLiaB`,
      dLiabRequest,
      {
        headers: this.headers,
      }
    );
  }

  verifyRecaptcha(token: String): Observable<any> {
    return this.http.get<any>(
      `${AppConfig.config.rootApi}Recaptcha?Token=${token}`,
      {
        headers: this.headers,
      }
    );
  }

  checkForAutorenewal(): Observable<any> {
    return this.http.get<any>(`${AppConfig.config.rootApi}autorenewal/`, {
      headers: this.headers,
    });
  }

  setRoute(route: string) {
    this.$route.next(route);
  }

  getServiceId() {
    return uuidv4();
  }

  getAutoRenewalPrices(priceGroupKey: string): Observable<any> {
    return this.http.get<any>(
      `${AppConfig.config.rootApi}AutoRenewal/GetPrices/${priceGroupKey}/`, {
        headers: this.headers,
    });
  }

  getSku(promoCode: string, osType: string): Observable<any> {
    osType = osType === 'M' ? 'mac' : 'win';
    return this.http.get(
      `${AppConfig.config.rootApi}AutoRenewal/GetSku/${promoCode}/${osType}/`, {
        responseType: 'text'
      });
  }

}
