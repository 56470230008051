<hrb-card>
  <form #addressForm="ngForm">
    <hrb-card-content>
      <div class="mb-sp-32">
        <span class="numberWrapper">2</span>&nbsp;
        <hrb-text style-type="small">Tell us about yourself.</hrb-text>
      </div>
      <ng-container *ngIf="!hideDemographicInfo">
        <div class="mb-sp-16">
          <hrb-text style-type="little"
            >Next, please provide us with some basic information about you so we
            can register your software. You'll only have to enter this
            information once.</hrb-text
          >
        </div>
        <div class="mb-sp-16">
          <hrb-layout layout="2col">
            <div>
              <hrb-input
                type="text"
                label="First Name*"
                required
                (keyup)="changeInReg('firstName', firstName.value)"
                [(ngModel)]="registration.firstName"
                name="firstName"
                #firstName="ngModel"
                [class.input-error]="
                  (firstName?.touched || forms?.controls.firstName?.touched) &&
                  forms?.controls.firstName.errors
                "
              ></hrb-input>
              <hrb-text
                class="error"
                *ngIf="
                  (firstName?.touched || forms?.controls.firstName?.touched) &&
                  forms?.controls.firstName.errors?.required
                "
                >{{ MSG.REQUIRED }}</hrb-text
              >
            </div>
            <div>
              <hrb-input
                label="Last Name*"
                [(ngModel)]="registration.lastName"
                (keyup)="changeInReg('lastName', lastName.value)"
                name="lastName"
                #lastName="ngModel"
                [class.input-error]="
                  (lastName?.touched || forms?.controls.lastName?.touched) &&
                  forms?.controls.lastName.errors
                "
              ></hrb-input>
              <hrb-text
                class="error"
                *ngIf="
                  (lastName?.touched || forms?.controls.lastName?.touched) &&
                  forms?.controls.lastName.errors?.required
                "
                >{{ MSG.REQUIRED }}</hrb-text
              >
            </div>
          </hrb-layout>
        </div>
        <div class="mb-sp-16">
          <hrb-input
            label="Address*"
            [(ngModel)]="registration.address"
            is-flush="true"
            (keyup)="changeInReg('address', address.value)"
            name="address"
            #address="ngModel"
            [class.input-error]="
              (address?.touched || forms?.controls.address?.touched) &&
              forms?.controls.address.errors
            "
          ></hrb-input>
          <hrb-text
            class="error"
            *ngIf="
              (address?.touched || forms?.controls.address?.touched) &&
              forms?.controls.address.errors?.required
            "
            >{{ MSG.REQUIRED }}</hrb-text
          >
        </div>
        <div class="mb-sp-16">
          <hrb-layout layout="3col">
            <div>
              <hrb-input
                label="City*"
                (keyup)="changeInReg('city', city.value)"
                name="city"
                #city="ngModel"
                [class.input-error]="
                  (city?.touched || forms?.controls.city?.touched) &&
                  forms?.controls.city.errors
                "
                [(ngModel)]="registration.city"
              ></hrb-input>
              <hrb-text
                class="error"
                *ngIf="
                  (city?.touched || forms?.controls.city?.touched) &&
                  forms?.controls.city.errors?.required
                "
                >{{ MSG.REQUIRED }}</hrb-text
              >
            </div>
            <div class="state-container">
              <input
                tabindex="-1"
                type="text"
                name="state"
                class="state-hidden"
                label="State*"
                (keyup)="changeInReg('state', state.value)"
                (change)="assignCodeToModel($event)"
              />
              <hrb-select
                label="State*"
                [(ngModel)]="registration.state"
                (keyup)="changeInReg('state', state.value)"
                (ngModelChange)="changeInReg('state', state.value)"
                name="state"
                #state="ngModel"
                [class.input-error]="
                  (state?.touched || forms?.controls.state?.touched) &&
                  forms?.controls.state.errors
                "
                (hrbFocus)="DropdownOpen()"
                (hrbChange)="DropdownClose()"
              >
                <option *ngFor="let abbrState of allStates" [value]="abbrState">
                  {{ abbrState }}
                </option>
              </hrb-select>

              <hrb-text
                class="error"
                *ngIf="
                  (state?.touched || forms?.controls.state?.touched) &&
                  forms?.controls.state.errors?.required
                "
                >{{ MSG.REQUIRED }}</hrb-text
              >
            </div>
            <div>
              <hrb-input
                type="text"
                mask-pattern="NNNNN"
                label="ZIP*"
                maxLength="5"
                mask-pattern="NNNNN"
                (keyup)="changeInReg('zip', zip.value)"
                name="zip"
                #zip="ngModel"
                [class.input-error]="
                  (zip?.touched || forms?.controls.zip?.touched) &&
                  forms?.controls.zip.errors
                "
                [(ngModel)]="registration.zip"
              ></hrb-input>
              <hrb-text
                class="error"
                *ngIf="
                  (zip?.touched || forms?.controls.zip?.touched) &&
                  forms?.controls.zip.errors?.required
                "
                >{{ MSG.REQUIRED }}</hrb-text
              >
              <hrb-text
                class="error"
                *ngIf="
                  (zip?.touched || forms?.controls.zip?.touched) &&
                  forms?.controls.zip.errors?.pattern
                "
                >Invalid zip format</hrb-text
              >
            </div>
          </hrb-layout>
        </div>
      </ng-container>

      <div class="mb-sp-16">
        <hrb-text style-type="little"
          >Please use a valid email. You’ll need to use this email if you want
          to re-install your software again.</hrb-text
        >
      </div>
      <div class="mb-sp-16">
        <hrb-layout layout="2col">
          <div>
            <hrb-input
              label="Email*"
              type="email"
              [(ngModel)]="registration.email"
              (keyup)="changeInReg('email', email.value)"
              name="email"
              #email="ngModel"
              [class.input-error]="
                (email?.touched || forms?.controls.email?.touched) &&
                forms?.controls.email.errors
              "
            ></hrb-input>
            <hrb-text
              class="error"
              *ngIf="
                (email?.touched || forms?.controls.email?.touched) &&
                forms?.controls.email.errors?.required
              "
              >{{ MSG.REQUIRED }}</hrb-text
            >
            <hrb-text
              class="error"
              *ngIf="
                (email?.touched || forms?.controls.email?.touched) &&
                forms?.controls.email.errors?.email
              "
              >{{ MSG.EMAIL }}</hrb-text
            >
          </div>
          <div>
            <hrb-input
              label="Confirm Email*"
              [(ngModel)]="registration.confirmEmail"
              (keyup)="changeInReg('confirmEmail', confirmEmail.value)"
              name="confirmEmail"
              #confirmEmail="ngModel"
              [class.input-error]="
                (confirmEmail?.touched ||
                  forms?.controls.confirmEmail?.touched) &&
                forms?.controls.confirmEmail.errors
              "
            ></hrb-input>
            <hrb-text
              class="error"
              *ngIf="
                (confirmEmail?.touched ||
                  forms?.controls.confirmEmail?.touched) &&
                forms?.controls.confirmEmail.errors?.required
              "
              >{{ MSG.REQUIRED }}</hrb-text
            >
            <hrb-text
              style-type="little"
              *ngIf="
                (confirmEmail?.touched ||
                  forms?.controls.confirmEmail?.touched) &&
                !forms?.controls.confirmEmail.errors?.required &&
                forms?.controls.confirmEmail.errors?.email
              "
              >Please use a valid email. You’ll need to use this email if you
              want to re-install your software again.</hrb-text
            >
            <div
              *ngIf="
                (confirmEmail?.touched ||
                  forms?.controls.confirmEmail?.touched) &&
                !forms?.controls.confirmEmail.errors?.required &&
                !forms?.controls.confirmEmail.errors?.email &&
                forms?.controls.confirmEmail.errors?.notMatch
              "
              style="color: red"
            >
              Entered email id does not match
            </div>
          </div>
        </hrb-layout>
      </div>
      <div class="mb-sp-32">
        <hrb-text style-type="little">* Required</hrb-text>
      </div>

      <hrb-checkbox
        (keyup)="changeInReg('emailOptIn', emailOptIn.value)"
        name="emailOptIn"
        #emailOptIn="ngModel"
        [(ngModel)]="registration.emailOptIn"
      >
        Please send me emails with helpful product information, in-season
        updates and other offers.
      </hrb-checkbox>
    </hrb-card-content>
  </form>
</hrb-card>
